<template>
  <span>
    <page-header title="Liste des types de séance" icon="fas fa-list"></page-header>
    <b-row class="row-header">
      <b-col><b>Nom</b></b-col>
      <b-col v-if="canChange"><b class="edit-color">Couleur</b></b-col>
    </b-row>
    <b-row v-for="seanceType in seanceTypes" :key="seanceType.id" class="row-line" :style="getColor(seanceType)">
      <b-col>{{ seanceType.name }}</b-col>
      <b-col v-if="canChange">
        <a href
         @click.prevent="showModal(seanceType)"
         :style="getColor(seanceType)"
         class="edit-color btn btn-secondary btn-sm"
        >
          <i class="fas fa-pen"></i> Modifier
        </a>
      </b-col>
    </b-row>
    <b-modal
      dialog-class="modal-lg"
      id="bv-modal-edit-color"
      @ok.prevent="onSave"
      ok-variant="primary"
      cancel-title="Annuler"
      ok-title="Modifier"
    >
      <template v-slot:modal-title>
        <b>
          <i class="fas fa-pen"></i>
          <span v-if="selectedSeanceType"> Modifier la couleur de {{ selectedSeanceType.name }}</span>
        </b>
      </template>
      <div v-if="errorText" class="error-text">{{ errorText }}</div>
      <color-select
        :background="background"
        :color="color"
        @selected="getSelectedColor($event)"
      />
    </b-modal>
  </span>
</template>

<script>
import PageHeader from '@/components/Layout/PageHeader'
import { BackendApi } from '@/utils/http'
import { BackendMixin } from '@/mixins/backend'
import { makeSeanceType } from '@/types/youth'
import ColorSelect from '@/components/Controls/ColorSelect'
import { mapActions } from 'vuex'

export default {
  name: 'seance-types-list',
  mixins: [BackendMixin],
  components: {
    ColorSelect,
    PageHeader,
  },
  data() {
    return {
      seanceTypes: [],
      background: '',
      selectedBackgroundColor: '',
      selectedTextColor: '',
      color: '',
      selectedSeanceType: null,
      errorText: '',
    }
  },
  mounted() {
    this.loadSeanceTypes()
  },
  computed: {
    canChange() {
      return this.hasPerm('youth.change_seancetype')
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    showModal(seanceType) {
      this.errorText = ''
      this.background = seanceType.backgroundColor
      this.color = seanceType.textColor
      this.selectedSeanceType = seanceType
      this.$bvModal.show('bv-modal-edit-color')
    },
    async loadSeanceTypes() {
      let url = '/api/youth/seance-types/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.seanceTypes = resp.data.map(elt => makeSeanceType(elt))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    getColor(elt) {
      return { backgroundColor: elt.backgroundColor, color: elt.textColor, }
    },
    getSelectedColor(event) {
      this.selectedBackgroundColor = event.color.background
      this.selectedTextColor = event.color.text
    },
    async onSave() {
      if (this.selectedSeanceType) {
        const data = {
          'background_color': this.selectedBackgroundColor,
          'text_color': this.selectedTextColor,
        }
        const url = '/api/youth/seance-types/' + this.selectedSeanceType.id + '/'
        let backendApi = new BackendApi('patch', url)
        try {
          let resp = await backendApi.callApi(data)
          await this.addSuccess(resp.data.message)
          this.$bvModal.hide('bv-modal-edit-color')
          await this.loadSeanceTypes()
        } catch (err) {
          this.errorText = this.getErrorText(err)
        }
      }
    },
  },
}
</script>

<style scoped>
.edit-color {
  float: right;
}
</style>
